/* eslint-disable no-unused-vars */
<template>
  <div style="margin-left: 20px; margin-right: 20px">
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      title="Лечебно-профилактические учреждения"
    />
    <a-button
      type="primary"
      style="margin-bottom: 10px"
      @click="$router.push({ path: 'add/', name: 'lpu.add' })"
      >Добавить ЛПУ</a-button
    >
    <label style="margin-left: 30px"
      >Искать:
      <a-auto-complete
        style="width: 30%"
        v-model="searchValue"
        @change="getClientSide"
        :data-source="searchClientData"
        placeholder="Начните вводить адрес"
      />
    </label>
    <a-button
      type="primary"
      style="margin-left: 5px"
      @click="getSearchedLpuList"
      >Найти</a-button
    >
    <a-button type="primary" style="margin-left: 5px" @click="getLpuList"
      >Сбросить фильтр</a-button
    >
    <a-table
      style="background-color: white"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      :row-key="(record) => record.id"
    >
      <template v-slot:action="text, record">
        <a-tooltip placement="top" title="Редактировать ЛПУ"
          ><a-button
            style="margin-right: 15px"
            @click="
              $router.push({
                path: 'edit/:id',
                name: 'lpu.edit',
                params: { id: record.id, lpuId: record.id },
              })
            "
            ><a-icon type="edit" /></a-button
        ></a-tooltip>
        <a-tooltip placement="top" title="Удалить ЛПУ"
          ><a-button @click="deleteLpu(text.id)"
            ><a-icon type="delete" /></a-button
        ></a-tooltip>
      </template>
    </a-table>
  </div>
</template>

<script>
import axios from "axios";

const columns = [
  {
    title: "id",
    dataIndex: "id",
    width: 70,
  },
  {
    title: "Название",
    dataIndex: "name",
    width: 300,
  },
  {
    title: "Адрес",
    dataIndex: "address",
    // ellipsis: true,
  },
  {
    title: "Phones",
    dataIndex: "phones",
    width: "300",
  },
  {
    title: "",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: 150,
  },
];

export default {
  data() {
    return {
      columns,
      data: [],
      error: null,
      lpuList: null,
      loading: false,
      pagination: {},
      searchValue: null,
      fullSearchedData: [],
      searchClientData: [],
    };
  },
  created() {
    this.getLpuList();
  },
  methods: {
    deleteLpu(lpu_id) {
      axios
        .patch(`/cp/health-facilities/${lpu_id}/`, {
          status: 1,
        })
        .then(() => {
          this.getLpuList();
        })
        .catch(() => {});
    },
    getLpuList() {
      this.loading = true;
      axios
        .get("/cp/health-facilities/")
        .then((response) => {
          this.data = response.data.data;
          this.fullSearchedData = response.data.data;
          this.error = null;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.newsList = null;
        });
    },
    getSearchedLpuList() {
      this.loading = true;
      axios
        .get("/cp/health-facilities/?search=" + this.searchValue)
        .then((response) => {
          this.data = response.data.data;
          this.error = null;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.newsList = null;
        });
    },
    openLpuEdit(lpu) {
      this.$router.push({
        path: "edit/:id",
        name: "lpu.edit",
        params: {
          id: lpu.id,
          lpuId: lpu.id,
        },
      });
      console.log(`openLpuEdit clicked ${lpu.id}`);
    },
    getClientSide() {
      this.searchClientData = !this.searchValue
        ? []
        : this.fullSearchedData
            .map((e) => e.address)
            .filter((e) =>
              e.toLowerCase().includes(this.searchValue.toLowerCase())
            );
    },
  },
  mounted() {},
};
</script>

<style scoped>
table {
  background-color: white;
}
th {
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  /* text-left text-xs
  font-medium
  text-gray-500
  uppercase
  tracking-wide */
}
td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
tbody tr:hover {
  cursor: pointer;
  background-color: rgb(241, 241, 253);
}
</style>
